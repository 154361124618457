import React from 'react'
import ResposiveImage, { IMedia } from '~/componentsV2/ResposiveImage'
import { ImageLink } from './styles'

interface ImageWithLinkProps {
  desktop: IMedia
  tablet: IMedia
  mobile: IMedia
  to?: string
}

export const ImageWithLink = (props: ImageWithLinkProps) => {
  const { to, ...rest } = props
  return (
    <ImageLink to={to || ''}>
      <ResposiveImage {...rest} />
    </ImageLink>
  )
}
