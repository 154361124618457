import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { RiCircleLine, RiCircleFill, RiPauseCircleLine, RiPlayCircleLine } from '@remixicon/react'
import {
  DotButton,
  DotCount,
  DotsCountWrapper,
  DotsWrapper,
  HeroSliderWrapper,
  InnerDot,
  ProgressBar,
  WrapperDots,
} from './styles'
import Slider, { CustomArrowProps } from 'react-slick'
import HeroSection from '../../sections/HeroSection'
import { PageBuilderSection } from '../PageBuilderSection'
import SliderArrowCustom from '~/components/products-carousel/chevron-arrow'
import useMediaQuery from '~/hooks/use-media-query'
import { ISlideProps } from './types'
import Slide from './Slide'
import HeroArrowCustom from './HeroArrow'

interface HeroSliderProps {
  slides: ISlideProps[]
}

const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <HeroArrowCustom position="right" {...props} />
)

const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <HeroArrowCustom {...props} />
)

const Dot = ({ active }: { active: boolean }) => {
  return (
    <InnerDot>
      <RiCircleLine className={active ? 'active-dot' : 'inactive-dot'} />
    </InnerDot>
  )
}

const HeroSlider2: React.FC<HeroSliderProps> = React.memo(({ slides }) => {
  const [progress, setProgress] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isPlaying, setIsPlaying] = useState(true)
  const progressInterval = useRef<NodeJS.Timeout>()
  const sliderRef = useRef<Slider>(null)
  const haveSlides = useMemo(
    () => slides.length > 0 && slides.every(slide => slide.image?.desktopImage),
    [slides]
  )

  // const startProgressBar = useCallback(() => {
  //   if (progressInterval.current) {
  //     clearInterval(progressInterval.current)
  //   }

  //   setProgress(0)

  //   if (isPlaying) {
  //     progressInterval.current = setInterval(() => {
  //       setProgress(prev => {
  //         if (prev >= 100) {
  //           clearInterval(progressInterval?.current)
  //           return 0
  //         }
  //         return prev + 1
  //       })
  //     }, 30)
  //   }
  // }, [isPlaying])

  // useEffect(() => {
  //   startProgressBar()
  //   return () => {
  //     if (progressInterval.current) {
  //       clearInterval(progressInterval.current)
  //     }
  //   }
  // }, [currentSlide, isPlaying, startProgressBar])

  // const handleBeforeChange = useCallback((oldIndex: number, newIndex: number) => {
  //   setCurrentSlide(newIndex)
  // }, [])

  // const handleMouseEnter = useCallback(() => {
  //   setIsPlaying(false)
  // }, [])

  // const handleMouseLeave = useCallback(() => {
  //   setIsPlaying(true)
  // }, [])

  // const _slides = useMemo(
  //   () =>
  //     Array.from({ length: 10 }, (_, index) => ({
  //       ...slides[0],
  //       id: index,
  //     })),
  //   [slides]
  // )

  const renderDots = useCallback(
    (dots: React.ReactNode) => {
      return (
        <WrapperDots>
          {/* <DotsWrapper>
            <DotButton onClick={() => setIsPlaying(!isPlaying)}>
              {isPlaying ? (
                <RiPauseCircleLine className="pause-icon" width={30} height={30} />
              ) : (
                <RiPlayCircleLine className="play-icon" width={30} height={30} />
              )}
            </DotButton>
            <ProgressBar progress={progress} />
            <DotsCountWrapper>
              <DotCount>{currentSlide + 1}</DotCount>
              <DotCount>{dots?.length}</DotCount>
            </DotsCountWrapper>
          </DotsWrapper> */}
        </WrapperDots>
      )
    },
    [progress]
  )

  const renderPaging = useCallback((i: number) => <Dot active={i === currentSlide} />, [
    currentSlide,
  ])

  if (!haveSlides) {
    return null
  }

  return (
    <HeroSliderWrapper className="slider-section">
      <Slider
        ref={sliderRef}
        dots={true}
        infinite={true}
        speed={1000}
        autoplay={isPlaying}
        autoplaySpeed={10000}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={true}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        appendDots={renderDots}
        customPaging={renderPaging}
      >
        {slides.map(slide => (
          <Slide key={slide.id} slide={{ ...slide, textPosition: slide?.textPosition?.position }} />
        ))}
      </Slider>
    </HeroSliderWrapper>
  )
})

HeroSlider2.displayName = 'HeroSlider2'

export default HeroSlider2
